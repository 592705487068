"use client";

import { Image, Button, Y, Input, X, Loader, Text } from "@reframe.so/blocks";
import { ArrowRightIcon } from "@reframe.so/icons/icons/ArrowRightIcon";
import { MailIcon } from "@reframe.so/icons/icons/MailIcon";
import { verifyCode } from "@src/auth/helpers";
import { NotWhiteLabel } from "@src/components/whitelabel";

import { useServerAction } from "@src/hooks/mutation";
import { EVENT_CONST } from "@src/mixpanel/constant";
import { useMixpanel } from "@src/mixpanel/context";
import { sendMagicLink } from "@src/resync/mutation";
import { signIn } from "next-auth/react";
import { useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";

const normalize = (email: string) =>
  email.trim().toLowerCase().replace(/\s+/g, "");

export const RefreshToken = ({
  token,
  next,
}: {
  token?: string;
  next?: string;
}) => {
  const router = useRouter();
  const loadingRef = useRef(false);

  useEffect(() => {
    if (!loadingRef.current) {
      loadingRef.current = true;
      signIn("credentials", {
        redirect: false,
        ...(token ? { token } : {}),
      }).then(
        (response) => {
          window.location.href = next ? "/" + next : "/";
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }, [router, token, next]);

  return <Loader />;
};

const handleSignInInsideIframe = () => {
  const dualScreenLeft = window.screenLeft ?? window.screenX;
  const dualScreenTop = window.screenTop ?? window.screenY;

  const width =
    window.innerWidth ?? document.documentElement.clientWidth ?? screen.width;

  const height =
    window.innerHeight ??
    document.documentElement.clientHeight ??
    screen.height;

  const systemZoom = width / window.screen.availWidth;

  const left = (width - 500) / 2 / systemZoom + dualScreenLeft;
  const top = (height - 550) / 2 / systemZoom + dualScreenTop;

  const newWindow = window.open(
    "/sign-in/google",
    "Sign in with google",
    `width=${500 / systemZoom},height=${
      550 / systemZoom
    },top=${top},left=${left}`
  );

  newWindow?.focus();

  return new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      try {
        if (newWindow?.closed) {
          clearInterval(interval);
          resolve();
        }
      } catch (e) {
        console.warn(e);
      }
    }, 50);
  });
};

export const SignInButtons = ({ next }: { next?: string }) => {
  const { track } = useMixpanel();
  const router = useRouter();

  const handleSignIn = async () => {
    // check if we are inside an iframe
    if (window.self !== window.top) {
      await handleSignInInsideIframe();

      // reload the page
      return window.location.reload();
    }

    signIn("google", { callbackUrl: "/" + (next ?? "chats") });
    track(EVENT_CONST.SIGN_IN_GOOGLE);
  };

  const [email, setEmail] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);

  const [sendingEmail, signInWithEmail] = useServerAction(
    (email: string) => sendMagicLink(email, window.location.origin, next),
    {
      onSuccess: () => {
        track(EVENT_CONST.SIGN_IN_EMAIL, { email });
        setCode("");
      },
    }
  );

  const [verifying, verify] = useServerAction(verifyCode, {
    onSuccess: (token) => {
      router.push(
        `/sign-in?${new URLSearchParams({
          token,
          next: next ?? "",
        }).toString()}`
      );

      setCode(null);
    },
  });

  const loading = sendingEmail || verifying;

  return (
    <Y css="gap-3 min-h-[96px] justify-center">
      <NotWhiteLabel>
        {email !== null ? null : (
          <Button onClick={handleSignIn} css="gap-2 text-base">
            <Image src="/assets/images/google.svg" alt="google" css="w-5 h-5" />
            Sign in with google
          </Button>
        )}
      </NotWhiteLabel>
      {email === null ? (
        <Button
          onClick={() => {
            setEmail("");
          }}
          css="gap-2 text-base items-center"
          variant="secondary"
        >
          <MailIcon css="w-5 h-5" width={1.25} />
          Sign in with email
        </Button>
      ) : code === null ? (
        <Y css="gap-1 items-center">
          <X css="gap-2 hug">
            <Input
              autoFocus
              type="email"
              placeholder="john@doe.com"
              value={email}
              onChange={(e) => setEmail(normalize(e.target.value))}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  signInWithEmail(email);
                }
                if (e.key === "Escape") {
                  setEmail(null);
                }
              }}
            />
            <Button
              loading={loading}
              disabled={
                loading || !/^[\w+-\.]+@([\w-]+\.)+[\w-]{2,10}$/.test(email)
              }
              variant="secondary"
              onClick={() => signInWithEmail(email)}
              css="gap-2 text-base"
            >
              <ArrowRightIcon css="w-5 h-5" width={2} />
            </Button>
          </X>
        </Y>
      ) : (
        <Y css="gap-2 items-center">
          <X css="gap-2 hug">
            <Input
              autoFocus
              placeholder="Verification code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  verify(email, code);
                }

                if (e.key === "Escape") {
                  setCode(null);
                }
              }}
            />
            <Button
              loading={loading}
              disabled={loading || code.length < 6}
              variant="secondary"
              onClick={() => verify(email, code)}
              css="gap-2 text-base"
            >
              <ArrowRightIcon css="w-5 h-5" width={2} />
            </Button>
          </X>
          <Text css="text-neutral-600 text-xs">
            Please check your email for the code.
          </Text>
        </Y>
      )}
    </Y>
  );
};
